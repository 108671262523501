
@media (max-width: 700px) {
 
    .logomobile {

        width: 100px!important;
    
    }
    

    }